<template>
  <section>
    <v-dialog
      v-if="dialog"
      v-model="dialog"
      persistent
      max-width="1500px"
      class="overflow-hidden h-600px"
    >
      <v-row no-gutters style="height: 90vh">
        <v-col cols="7" class="pr-1 h-100">
          <v-card class="h-100">
            <v-card-title
              class="d-flex justify-content-between h-75px custom-card-title"
            >
              <div>
                <span class="font-weight-medium m-4 text-h5"
                  >{{ $t("cycle_count") }}
                  <span class="font-size-h6 ml-1"
                    >({{ item.cycle_count_number }})</span
                  ></span
                >
              </div>

              <!-- <button @click="toggleModal">
                <span class="svg-icon mr-2">
                  <v-icon size="18">mdi-close</v-icon>
                </span>
              </button> -->
            </v-card-title>
            <v-card-text style="height: calc(90vh - 100px)">
              <v-container
                v-if="isDataLoaded"
                class="d-flex flex-column justify-content-between h-100 form-container"
              >
                <v-row class="flex-grow-0">
                  <v-col
                    v-for="(field, index) in fieldsWithDisplayOrder"
                    :key="index"
                    cols="12"
                    class="px-8"
                  >
                    <!-- text field -->
                    <FieldContainer
                      v-if="
                        field.field_type === 'text' ||
                        field.field_type === 'number'
                      "
                      :label="$t(field.field_name)"
                      :divider="!(index === fieldsWithDisplayOrder.length - 1)"
                      :disabled="!Boolean(field.is_editable)"
                    >
                      <v-text-field
                        v-model="formData[field.field_name]"
                        clearable
                        dense
                        outlined
                        :type="field.field_type"
                        class="rounded-lg h-50px"
                        append-icon="mdi-barcode-scan"
                        :disabled="!Boolean(field.is_editable)"
                        :ref="field.field_name"
                        @click:append="onEnterInput(field)"
                        @keyup.enter="onEnterInput(field)"
                        @blur="handleFormValidation(field)"
                        @input="handleFormValidation(field)"
                        :error-messages="errorMessages[field.field_name]"
                      ></v-text-field>
                    </FieldContainer>
                    <!-- swtich -->
                    <FieldContainer
                      v-else-if="field.field_type === 'boolean'"
                      :label="$t(field.field_name)"
                      :divider="!(index === fieldsWithDisplayOrder.length - 1)"
                      :disabled="!Boolean(field.is_editable)"
                    >
                      <v-switch
                        v-model="formData[field.field_name]"
                        inset
                        :true-value="1"
                        :false-value="0"
                      ></v-switch>
                    </FieldContainer>
                    <!-- select -->
                    <FieldContainer
                      v-else-if="field.field_type === 'select'"
                      :label="$t(field.field_name)"
                      :divider="!(index === fieldsWithDisplayOrder.length - 1)"
                      :disabled="!Boolean(field.is_editable)"
                    >
                      <v-select
                        class="rounded-lg h-50px"
                        v-model="formData[field.field_name]"
                        item-text="text"
                        item-value="index"
                        clearable
                        outlined
                        dense
                        :items="field.values"
                        :disabled="!Boolean(field.is_editable)"
                        @keyup.enter="onEnterInput(field)"
                        @blur="handleFormValidation(field)"
                        @input="handleFormValidation(field)"
                        :error-messages="errorMessages[field.field_name]"
                      ></v-select>
                    </FieldContainer>
                    <!-- date -->
                    <FieldContainer
                      v-else-if="field.field_type === 'date'"
                      :label="$t(field.field_name)"
                      :divider="!(index === fieldsWithDisplayOrder.length - 1)"
                      :disabled="!Boolean(field.is_editable)"
                    >
                      <SingleDatepicker
                        ref="datePicker"
                        :disabled="field.is_editable"
                        :date="formData[field.field_name]"
                        :name="field.field_name"
                        :onDateChange="onDateChange"
                      />
                    </FieldContainer>
                  </v-col>
                </v-row>
                <v-divider class="my-20" />
                <v-row class="flex-grow-1">
                  <v-col
                    cols="12"
                    class="pr-0 d-flex flex-column justify-content-between"
                  >
                    <v-row class="d-flex justify-content-center">
                      <v-col>
                        <PendingItems :id="item.id" />
                        <!-- <v-autocomplete
                          v-model="uom_id"
                          dense
                          ref="uom"
                          outlined
                          :items="serverData.uoms"
                          class="rounded-lg h-30px w-200px"
                          required
                          item-text="text"
                          item-value="index"
                          :label="$t('uom')"
                        ></v-autocomplete> -->
                      </v-col>
                      <v-col cols="auto">
                        <!-- <PackageDimensionUpdate
                          v-if="isPackageCodeVisible"
                          submitUrl="warehouse_management/custom/cycle_counts/update-package"
                          :asnId="item.id"
                          :packageId="formData.package_type_id"
                          :packageCode="formData.to_package_code"
                          :uoms="uoms_data"
                        /> -->
                      </v-col>
                      <v-col cols="auto">
                        <button
                          @click.prevent="reset(false)"
                          type="submit"
                          class="btn btn-secondary px-5 py-3"
                        >
                          {{ this.$t("clear") }}
                        </button>
                      </v-col>
                      <v-col cols="auto" class="mr-5">
                        <button
                          @click.prevent="save"
                          type="submit"
                          class="btn btn-info px-5 py-3"
                        >
                          {{ this.$t("save") }}
                        </button>
                      </v-col>
                    </v-row>
                    <!-- <v-row justify="center" class="align-items-end mt-10">
                      <v-col class="d-flex justify-center">
                        <button
                          @click.prevent="toggleModal"
                          type="submit"
                          class="btn btn--print-upload px-5 py-3"
                        >
                          {{ this.$t("close") }}
                        </button>
                      </v-col>
                    </v-row> -->
                  </v-col></v-row
                >
              </v-container>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col
          cols="5"
          class="d-flex flex-column justify-content-between h-100"
        >
          <!-- suggested details -->
          <SuggestedDetails :toggleModal="toggleModal" />
          <!-- scanned cases -->
          <ScannedItems :item="item" :toggleModal="toggleModal" />
        </v-col>
      </v-row>
    </v-dialog>
    <!-- modal end -->
    <Snackbar ref="snackbar" :message="notification" />
  </section>
</template>

<script>
import ApiService from "@/core/services/api.service";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import {
  UPDATE_TABLE_DATA,
  SET_SUGGESTED_DETAILS,
  SET_SCANNED_ITEMS,
} from "@/core/services/store/cycleCounts.module";
import FieldContainer from "@/own/components/FieldContainer.vue";
import ScannedItems from "@/own/components/warehouseManagement/cycleCounts/ScannedItems.vue";
import SuggestedDetails from "@/own/components/warehouseManagement/cycleCounts/SuggestedDetails.vue";
import PendingItems from "@/own/components/warehouseManagement/cycleCounts/PendingItems.vue";
// import PackageDimensionUpdate from "@/own/components/warehouseManagement/PackageDimensionUpdate.vue";
import Snackbar from "@/own/components/Snackbar.vue";
import SingleDatepicker from "@/own/components/SingleDatepicker.vue";

export default {
  name: "ActionsComponent",
  components: {
    FieldContainer,
    ScannedItems,
    SuggestedDetails,
    PendingItems,
    // PackageDimensionUpdate,
    Snackbar,
    SingleDatepicker,
  },
  // props: ["item"],

  data: () => ({
    dialog: false,
    serverData: null,
    formData: {},
    errorMessages: {},
    isDataLoaded: false,
    uom_id: null,
    notification: "",
    uoms_data: [],
  }),

  methods: {
    async toggleModal() {
      if (!this.dialog) {
        await this.loadDataFromServer();
      } else {
        this.$store.commit(SET_SUGGESTED_DETAILS, {
          operation: null,
          info: [],
        });
        this.$store.commit(SET_SCANNED_ITEMS, {
          operation: null,
          data: [],
        });
        this.$store.dispatch(UPDATE_TABLE_DATA, {
          warehouse_id: this.selectedWarehouse,
          ...this.$store.getters.getCYCLECOUNTSTableState,
        });
      }
      this.dialog = !this.dialog;
    },
    async loadDataFromServer() {
      this.$store.commit(SET_PAGE_LOADING, true);

      const payload = {
        warehouse_id: this.selectedWarehouse,
        id: this.item.id,
      };
      try {
        const response = await ApiService.post(
          `/warehouse_management/custom/cycle_counts/get-scanning-fields`,
          payload
        );
        const { data } = response;
        this.serverData = data;
        this.getDefaultValues();
        this.$store.commit(SET_SCANNED_ITEMS, {
          operation: null,
          data: data.scanned_items,
        });
        this.$store.commit(SET_SUGGESTED_DETAILS, {
          operation: null,
          info: {
            // asn_number: this.item?.asn_number,
            // reference_number: this.item?.reference_number,
            // client: this.item?.customer_component?.text1,
            ...data.header,
          },
        });
        this.uoms_data = data.uoms;
        this.isDataLoaded = true;
      } finally {
        this.$store.commit(SET_PAGE_LOADING, false);
      }
    },
    async onEnterInput(field) {
      const moveCursor = () => {
        //move cursor
        const displayFields = this.fieldsWithDisplayOrder; // Access computed property

        const currentIndex = displayFields.findIndex(
          (item) => item.field_name === field.field_name
        );

        for (
          let index = currentIndex + 1;
          index < displayFields.length;
          index++
        ) {
          if (
            displayFields[index].is_editable &&
            (displayFields[index].field_type === "text" ||
              displayFields[index].field_type === "number")
          ) {
            const ref = displayFields[index].field_name;
            this.$refs[ref][0].focus();
            break;
          }
        }
      };
      const getData = (url) => {
        this.$store.commit(SET_PAGE_LOADING, true);

        const payload = {
          warehouse_id: this.selectedWarehouse,
          id: this.item.id,
          ...this.formData,
        };
        return ApiService.post(url, payload)
          .then((response) => {
            if (response.data.success) {
              const { data } = response;
              const { task } = data;

              Object.keys(task).forEach((key) => {
                if (key in this.formData) {
                  this.formData[key] = task[key];
                }
              });
              // this.$store.commit(SET_SUGGESTED_DETAILS, {
              //   operation: null,
              //   info: asn_sku,
              // });
            }
          })
          .then(() => moveCursor())
          .finally(() => {
            this.$store.commit(SET_PAGE_LOADING, false);
          });
      };
      const parsableData = () => {
        this.$store.commit(SET_PAGE_LOADING, true);

        const payload = {
          customer_id: this.serverData.customer_id,
          module_id: this.serverData.module_id,
          parcelable_key: field.field_name,
          string: this.formData[field.field_name],
        };
        return ApiService.post("v1/values/get_parsed_data", payload)
          .then((response) => {
            if (response.data.success) {
              const { data } = response;
              const { parsed_data } = data;

              Object.keys(parsed_data).forEach((key) => {
                if (key in this.formData) {
                  this.formData[key] = parsed_data[key];
                }
              });
              // this.$store.commit(SET_SUGGESTED_DETAILS, {
              //   operation: null,
              //   info: asn_sku,
              // });
            }
          })
          .then(() => moveCursor())
          .finally(() => {
            this.$store.commit(SET_PAGE_LOADING, false);
          });
      };
      if (this.formData[field.field_name]) {
        if (field.is_parseable) {
          await parsableData();
        }
        if (field.is_master_scan) {
          await getData(field.url);
        }
        moveCursor();
      }
    },
    getDefaultValues() {
      this.serverData.fields.forEach((field) => {
        this.$set(this.formData, field.field_name, field.default_value);
        this.$set(this.errorMessages, field.field_name, []);
      });
      // this.uom_id = this.serverData.uoms[0].index;
    },
    handleFormValidation(field) {
      const { field_name, is_required, field_type } = field;

      this.errorMessages[field_name] = [];
      if (
        is_required &&
        (this.formData[field_name] === null ||
          this.formData[field_name] === undefined)
      ) {
        this.errorMessages[field_name].push(this.$t("this_field_is_required"));
      } else if (field_type === "number" && isNaN(this.formData[field_name])) {
        this.errorMessages[field_name].push(this.$t("this_must_be_a_number"));
      } else {
        this.errorMessages[field_name] = [];
      }
    },
    reset(persist = false) {
      Object.keys(this.formData).map((key) => {
        this.fieldsWithDisplayOrder.forEach((item) => {
          if (item.field_name === key) {
            if (persist && item.is_persist_input_value) {
              //
            } else if (item.default_value !== null) {
              this.formData[key] = item.default_value;
            } else {
              this.formData[key] = null;
            }
          }
        });
      });
    },
    onDateChange(val, name) {
      this.formData[name] = val;
    },
    save() {
      // validate form
      this.fieldsWithDisplayOrder.forEach((field) =>
        this.handleFormValidation(field)
      );

      if (this.hasAnyError) return;

      this.$store.commit(SET_PAGE_LOADING, true);

      const payload = {
        warehouse_id: this.selectedWarehouse,
        id: this.item.id,
        asn_sku_id: this.suggestedDetails.id,
        // uom_id: this.uom_id,
        ...this.formData,
      };

      ApiService.post(
        `/warehouse_management/custom/cycle_counts/update-scanned-items`,
        payload
      )
        .then((response) => {
          if (response.data.success) {
            this.notification = "Cycle count udpated";
            this.$refs.snackbar.trigger();

            this.$store.commit(SET_SCANNED_ITEMS, {
              operation: null,
              data: response.data.scanned_items,
            });
            this.$store.commit(SET_SUGGESTED_DETAILS, {
              operation: "spread",
              info: response.data.header,
            });
            this.reset(true);
          }
        })
        .finally(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
    },
  },

  computed: {
    selectedWarehouse() {
      return this.$store.getters.getSelectedWarehouse;
    },
    fieldsWithDisplayOrder() {
      return this.serverData
        ? [...this.serverData.fields].sort(
            (a, b) => a.display_order - b.display_order
          )
        : [];
    },
    hasAnyError: function () {
      return this.fieldsWithDisplayOrder.some(
        (item) => this.errorMessages[item.field_name].length
      );
    },
    suggestedDetails: function () {
      return this.$store.getters.getCYCLECOUNTSSuggestedDetails;
    },
    isPackageCodeVisible: function () {
      return this.fieldsWithDisplayOrder.some(
        (item) => item.field_name === "to_package_code"
      );
    },
    item: function () {
      return this.$store.getters.getItemForAction;
    },
  },
};
</script>

<style scoped lang="scss">
.form-container {
  height: calc(90vh - 90px);
  overflow-y: auto;
  overflow-x: hidden;
}
</style>

<style>
.custom-card-title {
  border-bottom: 1px dotted #dfe2ea;
}
</style>
